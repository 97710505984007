import { useEffect, useState, useCallback } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState, useResetRecoilState } from "recoil";
import userAtom from "store/user";
import isAuthenticatedAtom from "store/user/isAuthenticated";
import authTokenAtom from "store/user/authToken";
import useIsStandAlone from "hooks/useIsStandAlone";
import httpClient, { successfulStatus } from "service/httpClient";
import Spinner from "components/Spinner";
import config from "constants/config";

export function Authentication({ children }) {
    const setUser = useSetRecoilState(userAtom);
    const [isAuthenticated, setIsAuthenticated] = useRecoilState(isAuthenticatedAtom);
    const resetIsAuthenticated = useResetRecoilState(isAuthenticatedAtom);
    const [authToken, setAuthToken] = useRecoilState(authTokenAtom);
    const resetAuthToken = useResetRecoilState(authTokenAtom);
    const [initialSessionValidation, setInitialSessionValidation] = useState(false);
    const { isStandAlone } = useIsStandAlone();

    const validateAuthTokenResponse = useCallback(async () => {
        let payload = { token: authToken };

        let response = await httpClient.post(`${config.url.api}/auth/validate/`, payload, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (successfulStatus(response.meta.code)) {
            setUser(response.data.user_id);
            setAuthToken(authToken);
        } else {
            toast.info(response.meta.errors[0]);
            resetAuthToken();
        }

        setIsAuthenticated(response?.data?.is_valid || false);
        setInitialSessionValidation(true);
    }, [authToken]);

    useEffect(() => {
        if (authToken && !isAuthenticated) {
            validateAuthTokenResponse();
        } else {
            setInitialSessionValidation(true);
        }

        if (!authToken && isAuthenticated) {
            resetIsAuthenticated();
        }
    }, [authToken, isAuthenticated]);

    return (
        <>
            <TransitionGroup>
                {!initialSessionValidation && (
                    <CSSTransition classNames={isStandAlone ? "slideDown" : ""} timeout={{ enter: 0, exit: isStandAlone ? 1300 : 0 }}>
                        <Spinner width="100vw" height="100vh" size="large" mode="light" variant="colored" zIndex={999999} />
                    </CSSTransition>
                )}
            </TransitionGroup>

            {initialSessionValidation && children}
        </>
    );
}
