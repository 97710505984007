import { lazy, Suspense } from "react";
import SuspenseFallback from "parts/SuspenseFallback";

const EditCustomer = lazy(() => import("../../pages/Customers/EditCustomer"));
const CustomerDashboard = lazy(() => import("../../pages/Customers/Customer/CustomerDashboard"));
const CustomerAppointments = lazy(() => import("../../pages/Customers/Customer/CustomerAppointments"));
const AppointmentDashboard = lazy(() => import("../../pages/Customers/Customer/CustomerAppointments/AppointmentDashboard"));
const CustomerAnalysis = lazy(() => import("../../pages/Customers/Customer/CustomerAnalysis"));
const Sales = lazy(() => import("../../pages/Customers/Customer/Sales"));

export const customerRoutes = [
    {
        index: true,
        handle: {
            id: "customers"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <CustomerDashboard />
            </Suspense>
        )
    },
    {
        path: "edit",
        handle: {
            id: "customer_edit"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <EditCustomer />
            </Suspense>
        )
    },
    {
        path: "appointments",
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <CustomerAppointments />
            </Suspense>
        )
    },
    {
        path: "appointments/:appointment_uid",
        handle: {
            id: "customer_appointment"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <AppointmentDashboard />
            </Suspense>
        )
    },
    {
        path: "analysis",
        handle: {
            id: "customer_analysis"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <CustomerAnalysis />
            </Suspense>
        )
    },
    {
        path: "sales",
        handle: {
            id: "customer_sales"
        },
        element: (
            <Suspense fallback={<SuspenseFallback />}>
                <Sales />
            </Suspense>
        )
    }
];

export default customerRoutes;
