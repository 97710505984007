import { useState } from "react";
import CalendarContext from "./CalendarContext";

function CalendarProvider({ children }) {
    const [calendarDialogAppointment, setCalendarDialogAppointment] = useState(null);
    const [calendarDialogAppointmentMessages, setCalendarDialogAppointmentMessages] = useState(null);
    const [calendarDialogBreak, setCalendarDialogBreak] = useState(null);

    let contextValues = {
        calendarDialogAppointment: calendarDialogAppointment,
        calendarDialogAppointmentMessages: calendarDialogAppointmentMessages,
        calendarDialogBreak: calendarDialogBreak,
        setCalendarDialogAppointment: setCalendarDialogAppointment,
        setCalendarDialogAppointmentMessages: setCalendarDialogAppointmentMessages,
        setCalendarDialogBreak: setCalendarDialogBreak
    };

    return <CalendarContext.Provider value={contextValues}>{children}</CalendarContext.Provider>;
}

export default CalendarProvider;
