import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "components/Spinner";
import propTypes from "prop-types";
import styles from "./Button.module.scss";
import Tooltip from "components/Tooltip";

/**
 * @param {string} type button, submit, reset
 * @param {string} density auto, none, xsmall, small, medium, large, xlarge
 * @param {string} variant text, solid, outlined
 * @param {string} color primary, secondary, tertiary, quaternary, danger
 * @param {string} size xsmall, small, medium, large
 * @param {string} shape square, circle
 */
export function Button({
    children,
    as = "button",
    type = "button",
    disabled = false,
    id = null,
    className = "",
    icon = null,
    iconPosition = "left",
    iconOnly = false,
    iconSize = "medium",
    density = "auto",
    elevation = true,
    onClick = () => {},
    variant = "solid",
    color = "primary",
    size = "medium",
    shape = "square",
    fullWidth = false,
    loading = false,
    dotIndication = false,
    bold = true,
    title = null,
    autoFocus = false,
    ...rest
}) {
    const buttonRef = useRef();

    let cssClass = [styles["bttn"], styles[variant], styles[color], styles[size], styles[shape], styles[`density-${density}`], styles[`icon-size-${iconSize}`]];

    if (as === "button") {
        rest.type = type;
    }

    if (elevation) {
        cssClass.push(styles["elevation"]);
    }

    if (iconOnly) {
        cssClass.push(styles["icon-only"]);
    }

    if (fullWidth) {
        cssClass.push(styles["full"]);
    }

    if (!bold) {
        cssClass.push(styles["not-bold"]);
    }

    if (iconPosition === "right") {
        cssClass.push(styles["icon-position-right"]);
    }

    if (dotIndication) {
        cssClass.push(styles["dot-indicator"]);
    }

    let extraClass = className.split(" ").map((key) => {
        if (styles.hasOwnProperty(key)) {
            return styles[key];
        } else {
            return key;
        }
    });

    cssClass = [...cssClass, ...extraClass];

    if (loading) {
        disabled = true;
    }

    useEffect(() => {
        if (autoFocus) {
            buttonRef.current.focus();
        }
    }, [autoFocus]);

    const Tag = ["button", "label"].includes(as) ? as : Link;

    return (
        <Tooltip content={title} disabled={!title} touch={["hold", 500]}>
            <Tag ref={buttonRef} id={id} className={cssClass.join(" ")} onClick={onClick} disabled={disabled} role="button" {...rest}>
                {loading && <Spinner className={styles["is-loading"]} width="100%" mode={variant === "solid" ? `light` : `dark`} />}
                <span className={styles["content"]}>
                    {icon && <span className={styles["icon"]}>{icon}</span>}
                    {!iconOnly && <span>{children}</span>}
                </span>
            </Tag>
        </Tooltip>
    );
}

Button.propTypes = {
    as: propTypes.oneOf(["button", "label", "link"]),
    type: propTypes.oneOf(["button", "submit", "reset"]),
    density: propTypes.oneOf(["auto", "none", "xsmall", "small", "medium", "large", "xlarge"]),
    variant: propTypes.oneOf(["text", "solid", "outlined"]),
    color: propTypes.oneOf(["primary", "secondary", "tertiary", "quaternary", "quinary", "danger"]),
    size: propTypes.oneOf(["xsmall", "small", "medium", "large"]),
    iconSize: propTypes.oneOf(["xsmall", "small", "medium", "large", "xlarge", "xxlarge"]),
    iconPosition: propTypes.oneOf(["left", "right"]),
    shape: propTypes.oneOf(["square", "circle"])
};
