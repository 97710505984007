import moment from "moment";
import { atom, selector } from "recoil";

export const dateTimeAtom = atom({
    key: "dateTimeAtom",
    default: new Date()
});

export const currentDateSelector = selector({
    key: "currentDateSelector",
    get: ({ get }) => {
        const dateTime = get(dateTimeAtom);

        return moment(dateTime).format("YYYY-MM-DD");
    }
});
