import { useState } from "react";
import EmployeesContext from "./EmployeesContext";

function EmployeesProvider({ children }) {
    const [userRoleEditDialogUserId, setUserRoleEditDialogUserId] = useState(null);

    let contextValues = {
        userRoleEditDialogUserId: userRoleEditDialogUserId,
        setUserRoleEditDialogUserId: setUserRoleEditDialogUserId
    };

    return <EmployeesContext.Provider value={contextValues}>{children}</EmployeesContext.Provider>;
}

export default EmployeesProvider;
