import { RecoilRoot } from "recoil";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Routes from "routes";
import NotFoundPlaceholder from "components/NotFoundPlaceholder";
import Button from "components/Button";
import Pre from "components/Pre";
import Text from "components/Text";
import Authentication from "parts/Authentication";
import config from "constants/config";
import { ReactComponent as RefreshIcon } from "assets/icons/light/arrow-rotate-right.svg";
import "react-toastify/dist/ReactToastify.min.css";
import "./styles/styles.scss";

function App() {
    const [boundaryError, setBoundaryError] = useState(null);
    const [stackTrace, setStackTrace] = useState(null);

    return (
        <RecoilRoot>
            <ErrorBoundary
                onError={(error, info) => {
                    setBoundaryError(error);
                    setStackTrace(info?.componentStack);
                }}
                fallback={
                    <>
                        <NotFoundPlaceholder size="large" image="questions" title="Oops...">
                            <Text marginBottom={3}>Er is iets fout gegaan! Probeer de pagina te herladen.</Text>

                            <Button color="secondary" icon={<RefreshIcon />} onClick={() => window.location.reload()}>
                                Herladen
                            </Button>
                        </NotFoundPlaceholder>

                        {config?.environment !== "production" && (
                            <>
                                {boundaryError?.message && (
                                    <Pre marginBottom={3} marginLeft={3} marginRight={3}>
                                        {boundaryError.message}
                                    </Pre>
                                )}
                                {stackTrace && (
                                    <Pre marginBottom={3} marginLeft={3} marginRight={3}>
                                        {stackTrace}
                                    </Pre>
                                )}
                            </>
                        )}
                    </>
                }
            >
                <Authentication>
                    <Routes />
                </Authentication>
            </ErrorBoundary>
        </RecoilRoot>
    );
}

export default App;
