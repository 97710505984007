import { atom } from "recoil";
import config from "constants/config";

export const isAuthenticatedAtom = atom({
    key: "isAuthenticatedAtom",
    default: false,
    effects: [
        ({ onSet }) => {
            onSet((newValue, _oldValue, isReset) => {
                if (newValue) {
                    localStorage.setItem(config.storageKeys.authRefreshTokenTime, new Date().getTime());
                } else if (newValue === false || isReset) {
                    localStorage.removeItem(config.storageKeys.authRefreshTokenTime);
                }
            });
        }
    ]
});
