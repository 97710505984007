import { createContext } from "react";

const CalendarContext = createContext({
    calendarDialogAppointment: null,
    calendarDialogAppointmentMessages: null,
    calendarDialogBreak: null,
    setCalendarDialogAppointment: () => {},
    setCalendarDialogAppointmentMessages: () => {},
    setCalendarDialogBreak: () => {}
});

export default CalendarContext;
