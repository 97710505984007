import { useState } from "react";
import TreatmentsContext from "./TreatmentsContext";

function VacationLeaveProvider({ children }) {
    let contextValues = {};

    return <TreatmentsContext.Provider value={contextValues}>{children}</TreatmentsContext.Provider>;
}

export default VacationLeaveProvider;
