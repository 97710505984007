import { useState } from "react";
import ExtraWorkingHoursContext from "./ExtraWorkingHoursContext";

function ExtraWorkingHoursProvider({ children }) {
    const [extraWorkingHourEditDialogUid, setExtraWorkingHourEditDialogUid] = useState(null);
    const [showWorkingHourAddDialog, setShowWorkingHourAddDialog] = useState(false);

    let contextValues = {
        extraWorkingHourEditDialogUid: extraWorkingHourEditDialogUid,
        showWorkingHourAddDialog: showWorkingHourAddDialog,
        setExtraWorkingHourEditDialogUid: setExtraWorkingHourEditDialogUid,
        setShowWorkingHourAddDialog: setShowWorkingHourAddDialog
    };

    return <ExtraWorkingHoursContext.Provider value={contextValues}>{children}</ExtraWorkingHoursContext.Provider>;
}

export default ExtraWorkingHoursProvider;
