import { useEffect } from "react";
import { createPortal } from "react-dom";
import { ToastContainer, Bounce, cssTransition } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ScrollRestoration } from "react-router-dom";
import dateTimeAtom from "store/datetime";
import confettiAtom from "store/confetti";
import useWindowSize from "hooks/useWindowSize";
import Confetti from "components/Confetti";
import useInterval from "hooks/useInterval";

export function AppRoot({ children }) {
    const showConfetti = useRecoilValue(confettiAtom);
    const { windowWidth } = useWindowSize();
    const { counter } = useInterval(5);
    const setDateTime = useSetRecoilState(dateTimeAtom);

    useEffect(() => {
        setDateTime(new Date());
    }, [counter]);

    const fadeInTransition = cssTransition({
        enter: "toastFadeIn",
        exit: "toastFadeOut"
    });

    return (
        <>
            {children}
            {showConfetti && <Confetti />}
            {createPortal(<ToastContainer transition={fadeInTransition} position={windowWidth >= 1024 ? "top-center" : "top-right"} theme="colored" draggablePercentage={10} hideProgressBar={true} />, document.body)}
            <ScrollRestoration />
        </>
    );
}
