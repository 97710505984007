import { useState, useEffect } from "react";

export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        windowWidth: undefined,
        windowHeight: undefined
    });

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth > window.screen.availWidth ? window.screen.availWidth : window.innerWidth;
            const height = window.innerHeight > window.screen.availHeight ? window.screen.availHeight : window.innerHeight;

            setWindowSize({ windowWidth: width, windowHeight: height });
        };

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}
