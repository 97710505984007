import { lazy, Suspense } from "react";
import SuspenseFallback from "parts/SuspenseFallback";
import AppRoot from "parts/AppRoot";

const Login = lazy(() => import("../pages/Login"));

const publicRoutes = {
    get: [
        {
            path: "*",
            element: (
                <AppRoot>
                    <Suspense fallback={<SuspenseFallback />}>
                        <Login />
                    </Suspense>
                </AppRoot>
            )
        }
    ]
};

export default publicRoutes;
