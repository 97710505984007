import { useEffect, useState } from "react";

export default function useInterval(seconds = 60, intervalTrigger = () => {}) {
    const [counter, setCounter] = useState(0);
    const ms = seconds * 1000;

    useEffect(() => {
        if (seconds < 1) {
            throw new Error("Interval must be at least 1 second");
        }

        const interval = setInterval(() => {
            setCounter((prev) => prev + 1);
            intervalTrigger();
        }, ms);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return { counter };
}
