import { Suspense, lazy } from "react";
import { useSetRecoilState } from "recoil";
import confettiAtom from "store/confetti";
import styles from "./Confetti.module.scss";
const ConfettiExplosion = lazy(() => import("react-confetti-explosion"));

export function Confetti() {
    const setShowConfetti = useSetRecoilState(confettiAtom);

    const handleOnComplete = () => {
        setShowConfetti(false);
    };

    const colors = ["#ff3389", "#005dca", "#ffc700"];

    return (
        <Suspense fallback={<></>}>
            <div className={styles["container"]}>
                <ConfettiExplosion zIndex={9999} colors={colors} width={2000} height={"100vh"} particleCount={150} duration={3000} force={0.5} onComplete={handleOnComplete} />
            </div>
        </Suspense>
    );
}
