import { ReactComponent as ForbiddenImage } from "assets/illustrations/secure_files.svg";
import Text from "components/Text";
import styles from "./Forbidden.module.scss";

export function Forbidden({ children, title = "Geen toegang", content = "U kunt deze pagina niet bezoeken omdat u niet de juiste rechten heeft." }) {
    return (
        <div className={styles["container"]}>
            <figure className={styles["figure"]}>
                <ForbiddenImage />
            </figure>

            <article className={`${styles["content"]}`}>
                {!children && (
                    <>
                        <Text as="h1" marginBottom={2} className={styles["heading"]}>
                            {title}
                        </Text>
                        <p>{content}</p>
                    </>
                )}
                {children}
            </article>
        </div>
    );
}
