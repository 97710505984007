import { useState } from "react";
import CategoriesContext from "./CategoriesContext";

function CategoriesProvider({ children }) {
    const [showCategorySortDialog, setShowCategorySortDialog] = useState(false);

    let contextValues = {
        showCategorySortDialog: showCategorySortDialog,
        setShowCategorySortDialog: setShowCategorySortDialog
    };

    return <CategoriesContext.Provider value={contextValues}>{children}</CategoriesContext.Provider>;
}

export default CategoriesProvider;
