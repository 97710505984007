import { ReactComponent as InfoIcon } from "assets/icons/solid/circle-info.svg";
import SVG from "components/SVG";
import Text from "components/Text";
import styles from "./NotFoundPlaceholder.module.scss";

export function NotFoundPlaceholder({ children, image, size = "large", title = "" }) {
    const cssClass = [`${styles[`size--${size}`]}`];

    return image ? (
        <div className={`${cssClass.join(" ")} ${styles["figure-block"]}`}>
            <figure className={styles["figure"]}>
                <SVG name={image} type="illustrations" />
            </figure>

            <blockquote className={styles["item"]}>
                {title && (
                    <Text as="h2" marginBottom={1}>
                        {title}
                    </Text>
                )}
                {children}
            </blockquote>
        </div>
    ) : (
        <div className={`${cssClass.join(" ")} ${styles["text-block"]}`}>
            <blockquote className={styles["item"]}>
                <span className={styles["icon"]}>
                    <InfoIcon />
                </span>
                {children}
            </blockquote>
        </div>
    );
}
