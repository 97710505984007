import { useState } from "react";
import VariantsContext from "./VariantsContext";

function VariantsProvider({ children }) {
    const [showVariantAddDialog, setShowVariantAddDialog] = useState(false);

    let contextValues = {
        showVariantAddDialog: showVariantAddDialog,
        setShowVariantAddDialog: setShowVariantAddDialog
    };

    return <VariantsContext.Provider value={contextValues}>{children}</VariantsContext.Provider>;
}

export default VariantsProvider;
