import propTypes from "prop-types";
import styles from "./Spinner.module.scss";

export function Spinner({ children, className = "", width = "", height = "", size = "small", mode = "dark", variant = "default", zIndex = null }) {
    let css = {};

    if (width) {
        css.width = width;
    }

    if (height) {
        css.height = height;
    }

    if (zIndex) {
        css.zIndex = zIndex;
    }

    const cssClass = `${styles["loader-dotted"]} ${styles[`loader-dotted--${size}`]} ${styles[`loader-dotted--${mode}`]}`;

    return (
        <span className={`${className} ${styles["container"]} ${styles[`variant-${variant}`]}`} style={css}>
            {!children && (
                <span className={styles["loader-dotted-container"]} style={css}>
                    <span className={cssClass}>
                        <span className={styles.bounce1}></span>
                        <span className={styles.bounce2}></span>
                        <span className={styles.bounce3}></span>
                    </span>
                </span>
            )}
            {children}
        </span>
    );
}

Spinner.propTypes = {
    mode: propTypes.oneOf(["dark", "light"]),
    size: propTypes.oneOf(["small", "medium", "large"]),
    variant: propTypes.oneOf(["default", "colored", "shadow"])
};
