import { selector } from "recoil";
import usersAtom from "store/users";
import userAtom from "store/user";

/**
 * @returns Current user object
 */
export const currentUserSelector = selector({
    key: "currentUserSelector",
    get: ({ get }) => {
        const user_id = get(userAtom);
        const users = get(usersAtom);

        if (!users || !user_id) return null;

        return users.find((user) => user.user_id === user_id);
    }
});
