import { useState } from "react";
import WaitingListContext from "./WaitingListContext";

function WaitingListProvider({ children }) {
    const [showWaitingListAddDialog, setShowWaitingListAddDialog] = useState(false);

    let contextValues = {
        showWaitingListAddDialog: showWaitingListAddDialog,
        setShowWaitingListAddDialog: setShowWaitingListAddDialog
    };

    return <WaitingListContext.Provider value={contextValues}>{children}</WaitingListContext.Provider>;
}

export default WaitingListProvider;
