import { useState } from "react";
import AdditionalOpeningHoursContext from "./AdditionalOpeningHoursContext";

function AdditionalOpeningHoursProvider({ children }) {
    const [showAdditionalOpeningHourAddDialog, setShowAdditionalOpeningHourAddDialog] = useState(false);

    let contextValues = {
        showAdditionalOpeningHourAddDialog: showAdditionalOpeningHourAddDialog,
        setShowAdditionalOpeningHourAddDialog: setShowAdditionalOpeningHourAddDialog
    };

    return <AdditionalOpeningHoursContext.Provider value={contextValues}>{children}</AdditionalOpeningHoursContext.Provider>;
}

export default AdditionalOpeningHoursProvider;
