const env = process.env;
const prefix = "crm_";

export const config = {
    appVersion: env.REACT_APP_VERSION,
    locale: "nl-NL",
    environment: env.NODE_ENV,
    cacheName: "cache_",
    url: {
        api: env.REACT_APP_API_URL,
        blob: env.REACT_APP_BLOB_URL,
        lightspeed_api: env.REACT_APP_LIGHTSPEED_API_URL,
        nu_wel: "https://www.nu-wel.nl/",
        root: env.REACT_APP_ROOT_URL,
        website: "https://www.by-lauren.nl/"
    },
    storageKeys: {
        authToken: `${prefix}authToken`,
        authRefreshTokenTime: `${prefix}authRefreshTime`,
        calendarDate: `${prefix}calendarDate`,
        calendarSelectedEmployees: `${prefix}calendarSelectedEmployees`,
        calendarView: `${prefix}calendarView`,
        employees: `${prefix}employees`,
        selectedOutlet: `${prefix}selectedOutlet`,
        showCustomerDetails: `${prefix}showCustomerDetails`,
        waitingListSortOrder: `${prefix}waitingListSortOrder`,
        lastVisitedDate: `${prefix}lastVisitedDate`
    }
};
