import Tippy from "@tippyjs/react";
import styles from "./Tooltip.module.scss";

export function Tooltip({ children, arrow = false, content = "", theme = "dark", appendTo = () => document.body, zIndex = 200000, ...rest }) {
    const cssClass = [styles["simple"], styles[`theme--${theme}`]];

    return (
        <Tippy content={content} arrow={arrow} appendTo={appendTo} zIndex={zIndex} className={cssClass.join(" ")} {...rest}>
            {children}
        </Tippy>
    );
}
