import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { currentUserSelector } from "store/user/currentUser/currentUser";

export default function useUserAccess(roles = [], condition = null, conditionalRoles = []) {
    const currentUser = useRecoilValue(currentUserSelector);

    const hasAccess = useMemo(() => {
        let access = false;

        if (conditionalRoles?.length > 0 && conditionalRoles.includes(currentUser.role) && condition === true) {
            access = true;
        } else if (conditionalRoles.includes("*") && condition === true) {
            access = true;
        }

        if (roles.length > 0 && roles.includes(currentUser.role)) {
            access = true;

            if (conditionalRoles?.length === 0 && condition === false) {
                access = false;
            }
        }

        return access;
    }, [currentUser, roles, conditionalRoles, condition]);

    return hasAccess;
}
