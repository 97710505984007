import userAtom from "store/user";

const localStorageEffect =
    (key, byUser = false) =>
    ({ trigger, setSelf, onSet, getLoadable }) => {
        if (byUser) {
            const userLoadable = getLoadable(userAtom);

            if (userLoadable.state === "hasValue") {
                const { contents: user_id } = userLoadable;
                key = `${key}_${user_id}`;
            }
        }

        const storageValue = localStorage.getItem(key);

        if (trigger === "get") {
            if (storageValue !== null) {
                let value;

                try {
                    value = JSON.parse(storageValue);
                } catch (e) {
                    value = storageValue;
                }

                setSelf(value);
            }
        }

        onSet((newValue, _, isReset) => {
            if (isReset) {
                localStorage.removeItem(key);
            } else {
                if (typeof newValue === "object") {
                    localStorage.setItem(key, JSON.stringify(newValue));
                } else {
                    localStorage.setItem(key, newValue);
                }
            }
        });
    };

export default localStorageEffect;
