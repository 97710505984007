import { useState } from "react";
import FiltersContext from "./FiltersContext";

function FiltersProvider({ children }) {
    const [showFilterGroupAddDialog, setShowFilterGroupAddDialog] = useState(false);

    let contextValues = {
        showFilterGroupAddDialog: showFilterGroupAddDialog,
        setShowFilterGroupAddDialog: setShowFilterGroupAddDialog
    };

    return <FiltersContext.Provider value={contextValues}>{children}</FiltersContext.Provider>;
}

export default FiltersProvider;
