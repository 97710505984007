import { useEffect, useState } from "react";
import { createBrowserRouter } from "react-router-dom";
import { useRecoilValue } from "recoil";
import isAuthenticatedAtom from "store/user/isAuthenticated";
import publicRoutes from "./publicRoutes";
import privateRoutes from "./privateRoutes";

function useRouteBuilder() {
    const isAuthenticated = useRecoilValue(isAuthenticatedAtom);
    const [routes, setRoutes] = useState(publicRoutes.get);

    useEffect(() => {
        const router = isAuthenticated ? privateRoutes.get : publicRoutes.get;

        setRoutes(router);
    }, [isAuthenticated]);

    return createBrowserRouter(routes);
}

export default useRouteBuilder;
