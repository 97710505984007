var _cachedData = new Map();

export function getAPIHeaders(authToken) {
    /* prettier-ignore */
    return {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `${authToken}`,
            "Accept-Encoding": "gzip",
            "Accept-Language": "nl-NL"
        }
    };
}

const httpClient = {
    /**
     * Performs a GET request to the specified URL
     * @param  {String} url End-point of the request
     * @param  {Object} options Options for the request
     * @param  {String} authToken authToken, also sends OIS headers
     * @param  {Boolean} useCache True/false to write to cache
     * @param  {Object} config App config object required if useCache = true
     */
    get: async (url, options = {}, authToken = "", useCache = false, config = {}) => {
        if (useCache && Object.keys(config).length === 0) {
            new Error(`Parameter (config) required for request: ${url}`);
        }

        let defaultOptions = {
            method: "GET"
        };

        if (authToken) {
            defaultOptions = { ...defaultOptions, ...getAPIHeaders(authToken) };
        }

        let requestOptions = { ...defaultOptions, ...options };

        if (useCache) {
            let response = await getCachedResponse(url, requestOptions, config);
            return response;
        }

        const { data } = await request(url, requestOptions);
        return data;
    },

    /**
     * Performs a POST request to the specified URL
     * @param  {String} url End-point of the request
     * @param  {Object} data Payload
     * @param  {String} options Options for the request
     * @param  {String} authToken OIS authToken, also sends OIS headers
     */
    post: async (url, data, options, authToken = "") => {
        let defaultOptions = {
            method: "POST",
            body: formatData(data)
        };

        if (authToken) {
            defaultOptions = { ...defaultOptions, ...getAPIHeaders(authToken) };
        }

        let requestOptions = { ...defaultOptions, ...options };

        const { data: responseData } = await request(url, requestOptions);
        return responseData;
    },

    /**
     * Performs a POST request to the specified URL
     * @param  {String} url End-point of the request
     * @param  {Object} data Payload
     * @param  {String} options Options for the request
     * @param  {String} authToken OIS authToken, also sends OIS headers
     */
    put: async (url, data, options, authToken = "") => {
        let defaultOptions = {
            method: "PUT",
            body: formatData(data)
        };

        if (authToken) {
            defaultOptions = { ...defaultOptions, ...getAPIHeaders(authToken) };
        }

        let requestOptions = { ...defaultOptions, ...options };

        const { data: responseData } = await request(url, requestOptions);
        return responseData;
    },

    /**
     * Performs a POST request to the specified URL
     * @param  {String} url End-point of the request
     * @param  {Object} data Payload
     * @param  {String} options Options for the request
     * @param  {String} authToken OIS authToken, also sends OIS headers
     */
    delete: async (url, data, options, authToken = "") => {
        let defaultOptions = {
            method: "DELETE",
            body: formatData(data)
        };

        if (authToken) {
            defaultOptions = { ...defaultOptions, ...getAPIHeaders(authToken) };
        }

        let requestOptions = { ...defaultOptions, ...options };

        const { data: responseData } = await request(url, requestOptions);
        return responseData;
    }
};

async function getCachedResponse(url, options, config) {
    if (!_cachedData.has(url)) {
        _cachedData.set(
            url,
            new Promise(async (resolve, _reject) => {
                let cache = await caches.open(config.cacheName);
                let cacheResponse = await cache.match(url);

                if (cacheResponse) {
                    let promise = await Promise.resolve(cacheResponse);
                    const data = await responseType(promise);
                    _cachedData.set(url, data);
                    resolve(data);
                } else {
                    const { response, data } = await request(url, options);
                    cache.put(url, response);
                    _cachedData.set(url, data);
                    resolve(data);
                }
            })
        );
    }
    let result = await _cachedData.get(url);
    return result;
}

async function request(url, options = {}) {
    let response = await fetch(url, options);

    if (response.status >= 200 && response.status < 500) {
        let data = await responseType(response.clone());
        return { response: response.clone(), data: data };
    } else {
        return Promise.reject(new Error(`${response.status} ${response.statusText}`));
    }
}

async function responseType(response) {
    const contentType = response.headers.get("content-type");
    let result;

    if (contentType && contentType.includes("json")) {
        result = await response.json();

        let output = {};

        if (result.hasOwnProperty("meta")) {
            output = result;
        } else {
            output.status = response.status;
            output.data = result;
        }

        return output;
    } else if (contentType && contentType.includes("blob")) {
        return await response.blob();
    } else {
        result = await response.text();

        return {
            content: result,
            status: response.status
        };
    }
}

export function formatData(data) {
    return JSON.stringify(data, trimStrings);
}

function trimStrings(key, value) {
    if (typeof value === "string") {
        return value.trim();
    }

    return value;
}

export function successfulStatus(statusCode) {
    return statusCode >= 200 && statusCode < 300;
}

export function redirectionStatus(statusCode) {
    return statusCode >= 300 && statusCode < 400;
}

export function clientErrorStatus(statusCode) {
    return statusCode >= 400 && statusCode < 499;
}

export default httpClient;
