import PropTypes from "prop-types";
import styles from "./Text.module.scss";

export function Text({
    children,
    as: Tag = "p",
    className = "",
    color = "primary",
    weight = "normal",
    fontSize = "medium",
    italic = false,
    marginTop = 0,
    marginRight = 0,
    marginBottom = 0,
    marginLeft = 0,
    style,
    ...rest
}) {
    const cssClass = [
        styles[`weight-${weight}`],
        styles[`font-size-${fontSize}`],
        styles[`margin-top-${marginTop}`],
        styles[`margin-right-${marginRight}`],
        styles[`margin-bottom-${marginBottom}`],
        styles[`margin-left-${marginLeft}`],
        styles[`tag-${Tag}`],
        styles[`color-${color}`]
    ];

    if (className) {
        cssClass.push(className);
    }

    return (
        <Tag className={cssClass.join(" ")} {...rest}>
            {children}
        </Tag>
    );
}

Text.propTypes = {
    children: PropTypes.any.isRequired,
    italic: PropTypes.bool,
    weight: PropTypes.oneOf(["normal", "semibold", "bold"]),
    color: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
    marginTop: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7]),
    marginRight: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7]),
    marginBottom: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7]),
    marginLeft: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7])
};
