import styles from "./Pre.module.scss";

export function Pre({ children, elevation = true, marginTop = 0, marginRight = 0, marginBottom = 0, marginLeft = 0 }) {
    const cssClass = [styles["container"], styles[`margin-top-${marginTop}`], styles[`margin-right-${marginRight}`], styles[`margin-bottom-${marginBottom}`], styles[`margin-left-${marginLeft}`]];

    if (elevation) {
        cssClass.push(styles["elevation"]);
    }

    return <pre className={cssClass.join(" ")}>{children}</pre>;
}
