import { lazy, Suspense } from "react";
import SuspenseFallback from "parts/SuspenseFallback";
import CalendarProvider from "store/calendar/CalendarProvider";
import WaitingListProvider from "store/waitingList/WaitingListProvider";
import Page404 from "pages/Page404";
import AppRoot from "parts/AppRoot";
import customerRoutes from "routes/modules/customerRoutes";
import backOfficeRoutes from "routes/modules/backOfficeRoutes";

const PrivateRoot = lazy(() => import("../pages/PrivateRoot"));
const AddAppointment = lazy(() => import("../pages/Appointments/AddAppointment"));
const AddBreak = lazy(() => import("../pages/Breaks/AddBreak"));
const RecentlyAdded = lazy(() => import("../pages/RecentlyAdded"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Customers = lazy(() => import("../pages/Customers"));
const AddCustomer = lazy(() => import("../pages/Customers/AddCustomer"));
const Reservations = lazy(() => import("../pages/Reservations"));
const Cancellations = lazy(() => import("../pages/Cancellations"));
const WaitingList = lazy(() => import("../pages/WaitingList"));
const Calendar = lazy(() => import("../pages/Calendar"));
const MyProfile = lazy(() => import("../pages/MyProfile"));

const BookingLayout = lazy(() => import("../layout/BookingLayout"));
const AppointmentsLayout = lazy(() => import("../layout/AppointmentsLayout"));
const CustomersLayout = lazy(() => import("../layout/CustomersLayout"));
const CustomerLayout = lazy(() => import("../layout/CustomerLayout"));
const BackOfficeLayout = lazy(() => import("../layout/BackOfficeLayout"));

const privateRoutes = {
    get: [
        {
            path: "/",
            element: (
                <AppRoot>
                    <Suspense fallback={<SuspenseFallback />}>
                        <PrivateRoot />
                    </Suspense>
                </AppRoot>
            ),
            children: [
                {
                    index: true,
                    handle: {
                        id: "dashboard"
                    },
                    element: (
                        <Suspense fallback={<SuspenseFallback />}>
                            <Dashboard />
                        </Suspense>
                    )
                },
                {
                    path: "my-profile",
                    handle: {
                        id: "my_profile"
                    },
                    element: (
                        <Suspense fallback={<SuspenseFallback />}>
                            <MyProfile />
                        </Suspense>
                    )
                },
                {
                    element: (
                        <Suspense fallback={<SuspenseFallback />}>
                            <BookingLayout />
                        </Suspense>
                    ),
                    children: [
                        {
                            path: "appointment/add",
                            handle: {
                                id: "appointment_add"
                            },
                            element: (
                                <Suspense fallback={<SuspenseFallback />}>
                                    <AddAppointment />
                                </Suspense>
                            )
                        },
                        {
                            path: "break/add",
                            handle: {
                                id: "break"
                            },
                            element: (
                                <Suspense fallback={<SuspenseFallback />}>
                                    <AddBreak />
                                </Suspense>
                            )
                        }
                    ]
                },

                {
                    path: "customers",
                    element: (
                        <Suspense fallback={<SuspenseFallback />}>
                            <CustomersLayout />
                        </Suspense>
                    ),
                    children: [
                        {
                            index: true,
                            handle: {
                                id: "customers"
                            },
                            element: (
                                <Suspense fallback={<SuspenseFallback />}>
                                    <Customers />
                                </Suspense>
                            )
                        },
                        {
                            path: "add",
                            handle: {
                                id: "customer_add"
                            },
                            element: (
                                <Suspense fallback={<SuspenseFallback />}>
                                    <AddCustomer />
                                </Suspense>
                            )
                        }
                    ]
                },
                {
                    path: "customers/:uid",
                    handle: {
                        id: "customer_dashboard"
                    },
                    element: (
                        <Suspense fallback={<SuspenseFallback />}>
                            <CustomerLayout />
                        </Suspense>
                    ),
                    children: customerRoutes
                },
                {
                    path: "calendar",
                    handle: {
                        id: "calendar"
                    },
                    element: (
                        <Suspense fallback={<SuspenseFallback />}>
                            <CalendarProvider>
                                <Calendar />
                            </CalendarProvider>
                        </Suspense>
                    )
                },
                {
                    path: "appointments",
                    element: (
                        <Suspense fallback={<SuspenseFallback />}>
                            <AppointmentsLayout />
                        </Suspense>
                    ),
                    children: [
                        {
                            index: true,
                            handle: {
                                id: "recent"
                            },
                            element: (
                                <Suspense fallback={<SuspenseFallback />}>
                                    <RecentlyAdded />
                                </Suspense>
                            )
                        },

                        {
                            path: "reservations",
                            handle: {
                                id: "reservations"
                            },
                            element: (
                                <Suspense fallback={<SuspenseFallback />}>
                                    <Reservations />
                                </Suspense>
                            )
                        },
                        {
                            path: "cancellations",
                            handle: {
                                id: "cancellations"
                            },
                            element: (
                                <Suspense fallback={<SuspenseFallback />}>
                                    <Cancellations />
                                </Suspense>
                            )
                        },
                        {
                            path: "waiting-list",
                            handle: {
                                id: "waitingList"
                            },
                            element: (
                                <Suspense fallback={<SuspenseFallback />}>
                                    <WaitingListProvider>
                                        <WaitingList />
                                    </WaitingListProvider>
                                </Suspense>
                            )
                        }
                    ]
                },

                {
                    path: "backoffice",
                    handle: {
                        id: "backoffice"
                    },
                    element: (
                        <Suspense fallback={<SuspenseFallback />}>
                            <BackOfficeLayout />
                        </Suspense>
                    ),
                    children: backOfficeRoutes
                },
                {
                    path: "*",
                    element: <Page404 />
                }
            ]
        }
    ]
};

export default privateRoutes;
