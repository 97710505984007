import { ReactComponent as NotFoundImage } from "assets/illustrations/cancel.svg";
import Text from "components/Text";
import styles from "./NotFound.module.scss";

export function NotFound({ children }) {
    return (
        <div className={styles["container"]}>
            <figure className={styles["figure"]}>
                <NotFoundImage />
            </figure>

            <article className={`${styles["content"]}`}>
                {!children && (
                    <>
                        <Text as="h1" marginBottom={2} className={styles["heading"]}>
                            Oeps... pagina niet gevonden
                        </Text>
                        <p>Het lijkt erop dat deze pagina niet (meer) bestaat en verwijderd is. Blijft dit probleem zich voordoen? Meld dan het probleem bij de ontwikkelaar.</p>
                    </>
                )}
                {children}
            </article>
        </div>
    );
}
