import { useState } from "react";
import VacationLeaveContext from "./VacationLeaveContext";

function VacationLeaveProvider({ children }) {
    const [showVacationLeaveAddDialog, setShowVacationLeaveAddDialog] = useState(false);

    let contextValues = {
        showVacationLeaveAddDialog: showVacationLeaveAddDialog,
        setShowVacationLeaveAddDialog: setShowVacationLeaveAddDialog
    };

    return <VacationLeaveContext.Provider value={contextValues}>{children}</VacationLeaveContext.Provider>;
}

export default VacationLeaveProvider;
